.counter {
  display: flex;

  .counter-arrow {
    font-size: $h1-font-size;
    margin: auto 0;
    color: $primary;
    cursor: pointer;
  }

  .counter-value {
    font-size: $font-size-lg;
    margin: auto $spacer;
    cursor: default;
  }
}
