.support-list-group-item {
    display: flex;
    flex-direction: row;
    background-color: #F5F5F5;

    &.active {
        background-color: #d9f8ff;
        border-color: #32AFC9;
    }

    .icon-container {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        margin-right: 16px;
    }

    .labels-container {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        align-items: flex-start;
        font-size: 20px;
        
        .label {
            &.primary {
                color: #141414;
                font-weight: 600;
            }

            &.secondary {
                color: #404040;
            }
        }
    }
}