.asset-search-wrapper {
  position: relative;
}

.asset-search {
  position: absolute;
  max-height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  z-index: 1000;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
}

#search-button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  box-shadow: none !important;
  border: 1px solid #ced4da;
}

.customer-search {
  height: 50vh;
  overflow: hidden;
  overflow-y: auto;

  .parent-customer {
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
  }

  .child-customer {
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    margin-left: 20px;
  }
}
