// Overwrite Bootstrap's variables and add new ones here
// You can find them in node_modules/bootstrap/scss/_variables.scss
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$red: #d31145;

$primary: #71c7d9;
$dark: #414042;

// Adding (!) an additional theme color (ex. classes btn-red, bg-red)
//$theme-colors: (
//  "red": $red
//);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "myriad-pro-condensed", "myriad-pro", 'Noto Sans', "sans-serif";
$font-family-base: $font-family-sans-serif;

// Forms

$input-placeholder-color: #c0c0c0;
